import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/5HeS_MgtScQ"
    bibleGroupSrc="https://pdfhost.io/v/T2URV3W3B_Bible_Group_Homework_118pdf.pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/47322818"
  >
    <SEO title="Keeping Your Word - Holiday on its Head" />
  </Layout>
)

export default SermonPost
